import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import WebsiteIllustration from 'svgs/services/website.svg';

import { SUCCESS_STORIES } from '../constants';

import { StoryItem } from './components/story-item';

const STextWrapper = styled.div`
    text-align: center;
    max-width: 60rem;
    margin: 0 auto 5.625rem;

    ${CONSTANTS.MEDIA.max1024`
        margin-bottom: 3rem;
    `}
`;

const SHeader = styled(HeaderSecond)`
    margin-bottom: 1.5rem;
`;

const SFooter = styled(Paragraph)`
    text-align: center;
    max-width: 60rem;
    margin: 0 auto;
    font-size: 1.375rem;
    font-weight: 700;
`;

const SWebsiteIllustration = styled(WebsiteIllustration)`
    flex: 0.95;

    ${CONSTANTS.MEDIA.max1024`
      max-width: 28.125rem;
    `}

    ${CONSTANTS.MEDIA.max768`
      max-width: min(28.125rem, 90%);
    `}
`;

const SItemColumn = styled.ul`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 2.75rem;
`;

const SContentWrapper = styled.div`
    display: flex;
    gap: 8rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 5.625rem;

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column; 
        gap: 3rem;
        margin-bottom: 3rem;
    `}
`;

export const SuccessStoriesSection = () => {
    const title = 'view-webflow-agency.success-stories-section.title';

    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <Container>
            <STextWrapper>
                <SHeader id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                    <FormattedMessage id={title} />
                </SHeader>
                <Paragraph>
                    <FormattedMessage id="view-webflow-agency.success-stories-section.description" />
                </Paragraph>
            </STextWrapper>

            <SContentWrapper>
                <SItemColumn>
                    {SUCCESS_STORIES.map((story, index) => (
                        <StoryItem key={index} {...story} />
                    ))}
                </SItemColumn>
                <SWebsiteIllustration />
            </SContentWrapper>

            <SFooter>
                <FormattedMessage id="view-webflow-agency.success-stories-section.footer" />
            </SFooter>
        </Container>
    );
};
